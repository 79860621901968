//Sample test event file
import { run, Event, shutDownProcessing } from "./analytics_api_web_ext";
import { ca_engine_start , updateContext , fillURL } from "./bootstrap_web_ext";


//new Event("ext_heartbeat").Finish();
//shutDownProcessing();
//fillURL("file://<hostserver domain name or ip address>/Inetpub/DownloadProductRoot/sa/Win/CA/WAExtConfigContent/json/99.99/");
//ca_engine_start('{"device_uwp_mcafee_id":"McAFee101"}');
module.exports = {updateContext, Event , shutDownProcessing, ca_engine_start , fillURL};



